import { ExternalRoutes } from 'portal2-frontend'

export const externalRoutes: ExternalRoutes = {
	portal: process.env.REACT_APP_EXTERNAL_ROUTES_PORTAL, 
	login: process.env.REACT_APP_EXTERNAL_ROUTES_LOGIN, 
	logout: process.env.REACT_APP_EXTERNAL_ROUTES_LOGOUT, 
	profile: process.env.REACT_APP_EXTERNAL_ROUTES_PROFILE
}

export const appSettings = {
	enableAccessibeWidget: process.env.REACT_APP_ENABLE_ACCESSIBE_WIDGET === 'true',
	googleAnalyticsTag: process.env.REACT_APP_GOOGLE_ANALYTICS_TAG,
}

import { FormData } from '@netsyde/react-forms'
import { Application, ApplicationJson, NewApplicationSeed, parseApplicationJson, ValidationResult } from 'portal2-models'
import { trimBlankApplicationSubForms } from 'portal2-application'
import { camelCaseValidationErrors } from './common'
import { errorHandlerCallback, userDataRefreshCallback } from './index'
import { wrappedFetch as fetch } from './wrappedFetch'

export const getUserApplications = async () => {
	let applications: Array<FormData<Application>> = []

	try {
		const response = await fetch('/api/applications/userapplications')
		const applicationsJson = await response.json() as Array<FormData<ApplicationJson>>
		applications = applicationsJson.map(parseApplicationJson)
	}
	catch {
		applications = []
		console.error("Failed to retrieve applications from server")
	}

	return applications
}

export const createApplication = async (seed: NewApplicationSeed) => {

	try {
		const serverResponse = await fetch(`api/applications/new/${seed.applicationTypeId}/${seed.operationId}`, {
			method: 'POST',
			headers: {'Content-Type': 'application/json'}
		})

		const serverValidationResponse = await serverResponse.json() as ValidationResult<FormData<any>>
		const validationResponseJson: ValidationResult<FormData<ApplicationJson>> = camelCaseValidationErrors(serverValidationResponse)

		let validationResponse: ValidationResult<FormData<Application>> = { isValid: false, model: {}, validationErrors: [] }

		if (validationResponseJson.model) {
			// TODO: check why we have to cast here
			validationResponse = { ...validationResponseJson, model: parseApplicationJson(validationResponseJson.model) } as any as ValidationResult<FormData<Application>>
		}
		else {
			validationResponse = validationResponseJson as any as ValidationResult<FormData<Application>>
		}

		if (validationResponse.isValid) {
			userDataRefreshCallback && await userDataRefreshCallback()
		}

		return validationResponse
	}
	catch (err) {
		errorHandlerCallback ? errorHandlerCallback(err) : console.error("Failed to create application at server")
	}
}

export const saveApplication = async (applicationInput: FormData<Application>, doSubmit = false) => {
	const application = trimBlankApplicationSubForms(applicationInput)

	let validationResponse: ValidationResult<FormData<Application>> = { isValid: false, model: application, validationErrors: [] }

	const apiPath = doSubmit ? '/api/applications/submit' : '/api/applications/save'

	try {
		const response = await fetch(apiPath, {
			method: 'POST',
			body: JSON.stringify(application), 
			headers: {'Content-Type': 'application/json'}
		})

		const serverValidationResponse = await response.json() as ValidationResult<FormData<any>>
		const validationResponseJson: ValidationResult<FormData<ApplicationJson>> = camelCaseValidationErrors(serverValidationResponse)

		// TODO: check why we have to cast here
		validationResponse = { ...validationResponseJson, model: parseApplicationJson(validationResponseJson.model) } as any as ValidationResult<FormData<Application>>

		// check for any fields that don't align exactly with backend model
		// TODO: centralize and improve this hack
		const premiseIdValidationError = validationResponse.validationErrors.find(e => e.field === 'premisesId')
		if (premiseIdValidationError) {
			validationResponse.validationErrors.push(
				{ 
					field: 'premisesIdNumber',
					value: validationResponse.model.premisesIdNumber,
					errorMessage: premiseIdValidationError.errorMessage, 
				}
			)
		}

		if (validationResponse.isValid) {
			userDataRefreshCallback && await userDataRefreshCallback()
		}
		// TODO: eliminate this once we have validation field display working
		// else errorHandlerCallback && errorHandlerCallback(createErrorValidationError(validationResponse))

	}
	catch (err) {
		errorHandlerCallback ? errorHandlerCallback(err) : console.error("Failed to save application at server")
	}

	return validationResponse
}

export const deleteApplication = async (applicationId: number) => {
	try {
		const response = await fetch(`api/applications/delete/${applicationId}`, {
			method: 'POST',
			headers: {'Content-Type': 'application/json'}
		})

		if (response.ok) {
			userDataRefreshCallback && await userDataRefreshCallback()
		}
	}
	catch (err) {
		errorHandlerCallback ? errorHandlerCallback(err) : console.error("Failed to delete application at server")
	}
}
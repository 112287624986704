import { isAuthenticated } from './auth'

export const SESSION_EXPIRED = 'SESSION_EXPIRED'

export async function wrappedFetch(input: RequestInfo | URL, options?: RequestInit): Promise<Response> {
	try {
		const response = await fetch(input, options)
		return response
	}
	catch (error) {
		// see if we've lost our session
		const authInfo = await isAuthenticated()

		if (!authInfo.isAuthenticated) { 
			throw new Error(SESSION_EXPIRED)
		}
		else throw error
	}
}

export default wrappedFetch

import React, { ReactElement, useEffect, useState } from 'react'
import { Language, Constants, ALL_LANGUAGES } from 'portal2-models'
import { useAlertModal, LoadingOverlay } from 'portal2-components'
import { FrontEnd, UserData } from 'portal2-frontend'
import { externalRoutes, appSettings } from './config'
import { getConstants } from './api/constants'
import { isAuthenticated } from './api/auth'
import { setUserRefreshCallback, setErrorHandler } from './api/index'
import { getUserEnrolments } from './api/enrolments'
import { getUserApplications } from './api/applications'
import { api, SESSION_EXPIRED } from './api/index'
import { languageResources } from './languageResources'

// global accessibe widget interop
declare var acsbJS: any

export interface AppProps {
	basename: string
}

const languageStore = {
	set(language: Language) {
		localStorage.setItem('language', language)
	},

	get() {
		const storedLanguage = localStorage.getItem('language') as Language
		if (ALL_LANGUAGES.includes(storedLanguage)) return storedLanguage
		else return 'en'
	}
}

export const App: React.FunctionComponent<AppProps> = ({ basename }) => {
	const [constants, setConstants] = useState<Constants>()
	const [userData, setUserData] = useState<UserData>()
	const [language, setLanguage] = useState<Language>(languageStore.get())

	const setAndStoreLanguage = (language: Language) => {
		languageStore.set(language)
		setLanguage(language)
	}

	// Todo: add support for an error modal
	// Possibly more to front end and pass api errors as props
	const [alertModal, alert] = useAlertModal(language)

	const handleError = (error: Error) => {
		const localizedResources = languageResources[language]
		let errorContent: ReactElement

		if (error.message === SESSION_EXPIRED) {
			errorContent = (
				<div>
					<p>{localizedResources.SessionExpiredMessage}</p>
				</div>
			)

		}
		else {
			errorContent = (
				<div>
					<p>{error.message}</p>
					<code>{error.stack}</code>
				</div>
			)
		}

		const errorTitle = localizedResources[error.name] ?? error.name

		alert(errorContent, errorTitle)
	}

	const refreshUserData = async () => {
		const [enrolments, applications] = await Promise.all([getUserEnrolments(), getUserApplications()])

		setUserData(userData => {
			const newUserData = { ...userData}
			newUserData.enrolments = enrolments
			newUserData.applications = applications
			return newUserData
		})
	}

	setErrorHandler(handleError)
	setUserRefreshCallback(refreshUserData)

	const initialLoad = async () => {
		const authInfo = await isAuthenticated()

		// we direct user to the id server if they are not authenticated
		if (!authInfo.isAuthenticated) {
			window.location.href = externalRoutes.portal
			return
		}

		const constants = await getConstants()

		setConstants(constants)

		setUserData(userData => {
			const newUserData = { ...userData }
			newUserData.user = authInfo.user
			return newUserData
		})
	}

	useEffect(() => {
		if (!constants || !userData?.user) {
			initialLoad()
			refreshUserData()
		}
	}, [constants, userData?.user])

	useEffect(() => {
		if (appSettings.enableAccessibeWidget) {
			// Taken from id server source
            (function(){ var s = document.createElement('script'); var h = document.querySelector('head') || document.body; s.src = 'https://acsbapp.com/apps/app/dist/js/app.js'; s.async = true; s.onload = function(){ acsbJS.init({ statementLink : '', footerHtml : '', hideMobile : false, hideTrigger : false, disableBgProcess : false, language : 'en', position : 'right', leadColor : '#146FF8', triggerColor : '#146FF8', triggerRadius : '50%', triggerPositionX : 'right', triggerPositionY : 'bottom', triggerIcon : 'people', triggerSize : 'bottom', triggerOffsetX : 20, triggerOffsetY : 20, mobile : { triggerSize : 'small', triggerPositionX : 'right', triggerPositionY : 'bottom', triggerOffsetX : 20, triggerOffsetY : 20, triggerRadius : '20' } }); }; h.appendChild(s); })();
		}
		// TODO: Add google analytics tag.  May required single page app methods in react router to track page hits
	}, [])

	if (!constants || !userData) return <LoadingOverlay language='en' isLoading={true} />

	return (
		<div>
			<FrontEnd language={language} onLanguageChange={setAndStoreLanguage} userData={userData} constants={constants} api={api} externalRoutes={externalRoutes} />
			{alertModal}
		</div>
	)
}

export default App

import { ValidationResult, ValidationError } from 'portal2-models'

export function camelCaseValidationErrors<EntityT>(validationResult: ValidationResult<any>): ValidationResult<EntityT> {
	const { isValid, model, validationErrors: PascalCaseValidationErrors } = validationResult

	const validationErrors = PascalCaseValidationErrors.map(PascalCaseValidationError => {
		const { field, value, errorMessage } = PascalCaseValidationError

		const camelCaseValidationError = {
			value, errorMessage, 
			field: field && (field.charAt(0).toLowerCase() + field.slice(1))
		} as ValidationError<EntityT>

		return camelCaseValidationError
	})

	const camelCaseResult: ValidationResult<EntityT> = { isValid, model, validationErrors } 

	return camelCaseResult
}

import { User } from 'portal2-models'

type NegativeAuthenticationResult = {
	isAuthenticated: false
}

type PositiveAuthenticationResult = {
	isAuthenticated: true
	user?: User
}

export type AuthInfo = PositiveAuthenticationResult | NegativeAuthenticationResult

export const isAuthenticated = async (): Promise<AuthInfo> => {
	const response = await fetch('/api/auth', {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json'
		}
	})

	if (response.status === 200) {
		const data = await response.json() as AuthInfo
		return data
	} else {
		return {
			isAuthenticated: false
		}
	}
}
import { FormData } from '@netsyde/react-forms'
import { ValidationResult } from 'portal2-models'

import { mockApi } from 'portal2-frontend'
import { createEnrolment, saveEnrolment, deleteEnrolment } from './enrolments'
import { createApplication, saveApplication, deleteApplication } from './applications'
import { uploadAttachment, downloadAttachment, deleteAttachment } from './attachments'
export { SESSION_EXPIRED } from './wrappedFetch'

export interface UserDataRefreshCallback {
	(): Promise<void>
}

export interface ErrorHandlerCallback {
	(err: Error): void
}

export let userDataRefreshCallback: UserDataRefreshCallback | undefined = undefined
export let errorHandlerCallback: ErrorHandlerCallback | undefined = undefined 

export const setUserRefreshCallback = (callback: UserDataRefreshCallback) => userDataRefreshCallback = callback
export const setErrorHandler = (callback: ErrorHandlerCallback) => errorHandlerCallback = callback

// eliminate this once we have validation field display working
export function createErrorValidationError(validationResult: ValidationResult<FormData<any>>): Error {
	const { validationErrors } = validationResult

	const errorMessages = validationErrors.map(validationError => {
		const { field, errorMessage } = validationError
		return `${field}: ${errorMessage.en} | ${errorMessage.fr}`
	})

	const err =  new Error(errorMessages.join('\n'))
	err.name = 'Validation'

	return err
}

export const api = { 
	...mockApi, 
	createEnrolment, 
	createApplication, 
	saveEnrolment, 
	saveApplication, 
	deleteEnrolment, 
	deleteApplication,

	uploadAttachment, 
	downloadAttachment,
	deleteAttachment
}

export default api

import { FormData } from '@netsyde/react-forms'
import { Enrolment, EnrolmentJson, ValidationResult, parseEnrolmentJson } from 'portal2-models'
import { camelCaseValidationErrors } from './common'
import { errorHandlerCallback, userDataRefreshCallback } from './index'
import { wrappedFetch as fetch } from './wrappedFetch'

export const getUserEnrolments = async () => {
	let enrolments: Array<FormData<Enrolment>> = []

	try {
		const response = await fetch('/api/enrolments/userenrolments')
		const enrolmentsJson = await response.json() as Array<FormData<EnrolmentJson>>
		enrolments = enrolmentsJson.map(parseEnrolmentJson)

		// can isolate individual enrolments for testing here
		// enrolments = enrolments.slice(5, 6)
	}
	catch (err) {
		enrolments = []
		errorHandlerCallback ? errorHandlerCallback(err) : console.error("Failed to retrieve enrolments from server")
	}

	return enrolments
}

export const createEnrolment = async () => {
	let enrolment: FormData<Enrolment> | undefined 

	try {
		const response = await fetch('api/enrolments/new', {
			method: 'POST',
			headers: {'Content-Type': 'application/json'}
		})

		const enrolmentJson = await response.json() as FormData<EnrolmentJson>
		enrolment = parseEnrolmentJson(enrolmentJson)

		userDataRefreshCallback && await userDataRefreshCallback()
	}
	catch (err) {
		errorHandlerCallback ? errorHandlerCallback(err) : console.error("Failed to create enrolment at server")
	}

	return enrolment
}

export const saveEnrolment = async (enrolment: FormData<Enrolment>, doSubmit = false) => {
	let validationResponse: ValidationResult<FormData<Enrolment>> = { isValid: false, model: enrolment, validationErrors: [] }

	const apiPath = doSubmit ? '/api/enrolments/submit' : '/api/enrolments/save'

	try {
		const response = await fetch(apiPath, {
			method: 'POST',
			body: JSON.stringify(enrolment), 
			headers: {'Content-Type': 'application/json'}
		})

		const serverValidationResponse = await response.json() as ValidationResult<FormData<any>>
		const validationResponseJson: ValidationResult<FormData<EnrolmentJson>> = camelCaseValidationErrors(serverValidationResponse)

		// TODO: check why we have to cast here
		validationResponse = { ...validationResponseJson, model: parseEnrolmentJson(validationResponseJson.model) } as any as ValidationResult<FormData<Enrolment>>

		// check for any fields that don't align exactly with backend model
		// TODO: centralize and improve this hack
		const businessNumberValidationError = validationResponse.validationErrors.find(e => e.field === 'businessNumber')
		if (businessNumberValidationError) {
			validationResponse.validationErrors.push(
				{ 
					field: 'businessNumberPrefix', 
					value: validationResponse.model.businessNumberPrefix, 
					errorMessage: businessNumberValidationError.errorMessage, 
				}
			)
		}

		if (validationResponse.isValid) {
			userDataRefreshCallback && await userDataRefreshCallback()
		}
		// TODO: eliminate this once we have validation field display working
		// else errorHandlerCallback && errorHandlerCallback(createErrorValidationError(validationResponse))

	}
	catch (err) {
		errorHandlerCallback ? errorHandlerCallback(err) : console.error("Failed to save enrolment at server")
	}

	return validationResponse
}

export const deleteEnrolment = async (operationId: number) => {
	try {
		const response = await fetch(`api/enrolments/delete/${operationId}`, {
			method: 'POST',
			headers: {'Content-Type': 'application/json'}
		})

		if (response.ok) {
			userDataRefreshCallback && await userDataRefreshCallback()
		}
	}
	catch (err) {
		errorHandlerCallback ? errorHandlerCallback(err) : console.error("Failed to delete enrolment at server")
	}
}
const en = {
	Error: 'Error',
	SessionExpiredMessage: 'Your session appears to have expired. Please try logging out and back in again.',
}

export type LocalizedResources = typeof en

const fr: LocalizedResources = {
	Error: 'Erreur',
	SessionExpiredMessage: 'Votre session semble avoir expirée. Veuillez essayer de vous déconnecter et de vous reconnecter.'
}

export const languageResources = { en, fr }
import { Constants, mockConstants, JsonStringDates, parseConstantsJson } from 'portal2-models'

export const getConstants = async () => {
	const response = await fetch('/api/constants')

	let constants = mockConstants

	try {
		const serverConstants = await response.json() as JsonStringDates<Constants>
		constants = parseConstantsJson(serverConstants)
	}
	catch {
		console.error("Failed to retrieve constants from server")
	}

	return constants
}

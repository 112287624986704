import { Attachment } from 'portal2-models'
import { errorHandlerCallback, userDataRefreshCallback } from './index'
import { wrappedFetch as fetch } from './wrappedFetch'

export const downloadAttachment = async (attachmentId: number) => {
	try {
		const response = await fetch(`/api/applications/attachment/${attachmentId}`)
		const blob = await response.blob() 
		return blob
	}
	catch (err) {
		errorHandlerCallback ? errorHandlerCallback(err) : console.error("Failed to retrieve attachment from server")
	}
}

export const uploadAttachment = async (applicationId: number, file: File) => {
	let response: Response

	try {
		const formData = new FormData()
		formData.append('file', file)

		response = await fetch(`/api/applications/attachment/upload/${applicationId}`, {
			method: 'POST',
			body: formData,
		})

		const attachment = await response.clone().json() as Attachment
		return attachment
	}
	catch (err) {
		// This is pretty ugly.  TODO: fix/combine/streamline this.
		try {
			const invalidFileType = "Invalid FileType";
			const errorText = await response.text()
			if (errorText.includes(invalidFileType)) {
				const fileTypeError = new Error(invalidFileType)
				errorHandlerCallback ? errorHandlerCallback(fileTypeError) : console.error(invalidFileType)
			}
			else {
				const moreInfoError = new Error(err)
				moreInfoError.message = errorText + "\n" + moreInfoError.message 
				errorHandlerCallback ? errorHandlerCallback(moreInfoError) : console.error("Failed to upload attachment to server")
			}
		}
		catch {
			errorHandlerCallback ? errorHandlerCallback(err) : console.error("Failed to upload attachment to server")
		}
	}
}

export const deleteAttachment = async (attachmentId: number) => {
	try {
		const response = await fetch(`api/applications/attachment/delete/${attachmentId}`, {
			method: 'POST',
			headers: {'Content-Type': 'application/json'}
		})

		if (response.ok) {
			userDataRefreshCallback && await userDataRefreshCallback()
		}
	}
	catch (err) {
		errorHandlerCallback ? errorHandlerCallback(err) : console.error("Failed to delete application at server")
	}
}